import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Response from '../../components/Response';
import { saveOpinion, selectOption, saveStereotypes } from '../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';


import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { setMediaFilter, selectIssue, selectOption, saveAPIToken, saveIssueTitle } = state;
  return {
    isMobile: "MOBILE" === setMediaFilter,
    issue: selectIssue,
    option: selectOption,
    token: saveAPIToken,
    issueTitle: saveIssueTitle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    opinion: (opinion) => dispatch(saveOpinion(opinion)),
    select: (option) => dispatch(selectOption(option)),
    save_stereotype: (stereotype) => dispatch(saveStereotypes(stereotype))
  }
}

class ExpectScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      issueTitle: '',
      proAge: ['모든 연령대'],
      proGender: ['모든 성별'],
      proPoliticalStance: ['모든 정치 성향'],
      conAge: ['모든 연령대'],
      conGender: ['모든 성별'],
      conPoliticalStance: ['모든 정치 성향']
    }
  }

  componentDidMount() {
    const { match, token } = this.props;
    const key = match.params.key
    fetch(`${process.env.REACT_APP_URL}/dev/issues/key?key=${key}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(response => {
        this.setState({
          issueTitle: response.issue.title
        })
        // select(response.issue._id  || null)
        // saveIssueTitle(response.issue.title || null)
        // saveIssueKey(response.issue.key || null)
        // this.setState({
        //     prevOpinion: response.prevOpinion
        // })
        // localStorage.setItem('prevOpinion', JSON.stringify(response.prevOpinion));
      })

    this.onNextClickHandler = this.onNextClickHandler.bind(this);
    this.updateStereotype = this.updateStereotype.bind(this)
  }


  onNextClickHandler() {
    const { match, token, history, save_stereotype } = this.props
    const { proAge, proGender, proPoliticalStance, conAge, conGender, conPoliticalStance } = this.state
    const key = match.params.key
    const stereotype = {
      issueKey: key,
      pro: {
        age: [proAge],
        gender: [proGender],
        politicalStance: [proPoliticalStance]
      },
      con: {
        age: [conAge],
        gender: [conGender],
        politicalStance: [conPoliticalStance]
      }
    }
    save_stereotype(stereotype)

    fetch(`${process.env.REACT_APP_URL}/dev/expectations/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(stereotype)
    })
      .then(res => res.json())
      .then(response => {
        history.push(`/result/${key}`)
      })

  }

  updateStereotype (event) {

    // const { options } = event.target
    // const value = []

    // for (let i = 0, l = options.length; i< l; i+=1) {
    //   if (options[i])
    // }
    console.log(event)
    let updatedState = {}
    if (event.target.value.length === 0) {
      if (event.target.name === 'proAge' || event.target.name === 'conAge') {
        updatedState[event.target.name] = ['모든 연령대']
      } else if (event.target.name === 'proGender' || event.target.name === 'conGender') {
        updatedState[event.target.name] = ['모든 성별']
      } else if (event.target.name === 'proPoliticalStance' || event.target.name === 'conPoliticalStance') {
        updatedState[event.target.name] = ['모든 정치 성향']
      }
    } else {
      updatedState[event.target.name] = event.target.value
    }
    this.setState(updatedState)
  }

  stereoType(type) {
    const { proAge, proGender, proPoliticalStance, conAge, conGender, conPoliticalStance } = this.state;
    const politicalStance = ['보수', '중도보수', '중도', '중도진보', '진보'];
    const gender = ['여성', '남성', '그 외'];
    const age = ['10대', '20대', '30대', '40대', '50대', '60대', '70대 이상'];

    if (type === 'pro') {
      return (
        <div className="filter-button-wrapper wide">
          <div className="dropdown-button">
            <div className="dropdown-filter">
              <FormControl>
                <Select
                  multiple
                  name="proAge"
                  value={proAge}
                  onChange={this.updateStereotype}
                  input={<Input id="select-multiple" />}
                  renderValue={
                    (selected) => {
                      if (selected.length === 0) return "연령대"
                      else return proAge.join(', ')
                    }
                  }
                >
                  <MenuItem value='모든 연령대'> 모든 연령대 </MenuItem>
                  {
                    age.map(stance => (
                      <MenuItem key={stance} value={stance} className={proAge.includes(stance) ? 'selected' : ''}>
                        {stance}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="dropdown-button">
            <div className="dropdown-filter">
              <FormControl>
                <Select
                  name="proGender"
                  multiple
                  value={proGender}
                  onChange={this.updateStereotype}
                  input={<Input id="select-multiple" />}
                  renderValue={
                    selected => {
                      if (selected.length === 0) return "성별"
                      else return proGender.join(', ')
                    }
                  }
                >
                  <MenuItem value='모든 성별'> 모든 성별 </MenuItem>
                  {
                    gender.map(stance => (
                      <MenuItem key={stance} value={stance}>
                        {stance}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="dropdown-button">
            <div className="dropdown-filter">
              <FormControl>
                <Select
                  name="proPoliticalStance"
                  multiple
                  value={proPoliticalStance}
                  onChange={this.updateStereotype}
                  input={<Input id="select-multiple" />}
                  renderValue={
                    selected => {
                      if (selected.length === 0) return "정치성향"
                      else return proPoliticalStance.join(', ')
                    }
                  }
                >
                  <MenuItem value='모든 정치 성향'> 모든 정치 성향 </MenuItem>
                  {
                    politicalStance.map(stance => (
                      <MenuItem key={stance} value={stance}>
                        {stance}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      )
    } 
    else if (type === 'con') {
      return (
      <div className="filter-button-wrapper wide">
        <div className="dropdown-button">
          <div className="dropdown-filter">
            <FormControl>
              <Select
                name="conAge"
                multiple
                value={conAge}
                onChange={this.updateStereotype}
                input={<Input id="select-multiple" />}
                renderValue={
                  selected => {
                    if (selected.length === 0) return "연령대"
                    else return conAge.join(', ')
                  }
                }
              >
                <MenuItem value='모든 연령대'> 모든 연령대 </MenuItem>
                {
                  age.map(stance => (
                    <MenuItem key={stance} value={stance}>
                      {stance}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="dropdown-button">
          <div className="dropdown-filter">
            <FormControl>
              <Select
                name="conGender"
                value={conGender}
                multiple
                onChange={this.updateStereotype}
                input={<Input id="select-multiple" />}
                renderValue={
                  selected => {
                    if (selected.length === 0) return "성별"
                    else return conGender.join(', ')
                  }
                }
              >
                <MenuItem value='모든 성별'> 모든 성별 </MenuItem>
                {
                  gender.map(stance => (
                    <MenuItem key={stance} value={stance}>
                      {stance}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="dropdown-button">
          <div className="dropdown-filter">
            <FormControl>
              <Select
                name="conPoliticalStance"
                value={conPoliticalStance}
                multiple
                onChange={this.updateStereotype}
                input={<Input id="select-multiple" />}
                renderValue={
                  selected => {
                    if (selected.length === 0) return "정치성향"
                    else return conPoliticalStance.join(', ')
                  }
                }
              >
                <MenuItem value='모든 정치 성향'> 모든 정치 성향 </MenuItem>
                {
                  politicalStance.map(stance => (
                    <MenuItem key={stance} value={stance}>
                      {stance}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      )
    }

  }

  render() {
    const { isLoading, issueTitle } = this.state;
    // const { issueTitle } = this.props;
    return (
      <div className="wrapper">
        <Header />
        {
          isLoading &&
          <div className="answer-issue-loader">
            <div className="circular-progress-wrapper">
              <CircularProgress color="secondary" />
            </div>
          </div>
        }
        {
          <div className="container">
            {/* <div className="text-light text-center">
              
            </div> */}
            <div className="title text-light text-center title-issue">
              "{issueTitle}"에 동의할 사람은 어떤 사람일까요?
            </div>
            <div>
              {this.stereoType('pro')}
            </div>
          </div>
        }
        {
          <div className="container">
            <div className="title text-light text-center title-issue">
              "{issueTitle}"에 반대할 사람은 어떤 사람일까요?
            </div>
            <div>
              {this.stereoType('con')}
            </div>
          </div>
        }
        {/* <Response responseType={responseType} options={info}/> */}
        <div className="next">
          <Button type={`select text-bold`} content="다음" onClick={this.onNextClickHandler} />
        </div>
      </div>
    )
  }
}

ExpectScreen.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  issue: PropTypes.string.isRequired,
  option: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpectScreen);