import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function ShareDialog(props) {

    const [open, setOpen] = React.useState(false)
    // const { open, onOpen, onClose } = props;

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const moveToMainPage = () => {
        window.location.href = '/'
    }

    return (
      <div>
        {/* <div className="event-banner" onClick={onOpen}>
            <span role="img" aria-label="tada">🎉</span>별별생각을 남기면 기프티콘을 드립니다! <span role="img" aria-label="tada">🎉</span>
        </div> */}
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                <span role="img" aria-label="tada">🎉</span>별별생각 기프티콘 이벤트!
            </DialogTitle>
            <DialogContent>
              {/* <div className="popup"> */}
                  <p>우리 사회의 이슈에 대한 자세한 생각을 남겨주세요! 30분을 추첨하여 10,000원 상당의 기프티콘을 드립니다.</p>
                  <ol>
                    <li>카카오 계정으로 의견을 남겨주세요! <a className="link" onClick={moveToMainPage}>링크로 가기</a></li>
                    <li>설문에 답변한 뒤, 이슈를 선택하고 '생각 남기기'에서 주관식 의견을 적어주세요!</li>
                    <li>더 많은 이슈에 대해 주관식 의견을 남겨주실수록 당첨 확률이 높아집니다!</li>
                  </ol>
                    <li>이미 설문에 참여하신 분들도 주관식 응답을 남겨주시면 자동으로 응모됩니다. </li>
                    <li>익명으로 주관식 응답을 쓰셨더라도 카카오 계정으로 로그인하시면 자동으로 응모됩니다. </li>
                    <li>당첨되신 분께는 11월 6일까지 카카오 계정에 등록된 이메일을 통해 안내드릴 예정입니다.</li>
              {/* </div> */}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={moveToMainPage} variant="text" color="secondary">카카오 로그인</Button> */}
              <Button onClick={onClose} variant="text" color="secondary">닫기</Button>
            </DialogActions>
        </Dialog>
      </div>
    );
}

export default ShareDialog